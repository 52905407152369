export class QueryResultData {

	dictionaryName: string;
	resultsCount: number;
	entries: string[];
	pageSize: number;
	pageIndex: number;
	pageResultsCount: number;

	filtered: boolean = false;
	skippedPages: number[] = [];
	lastPage: boolean = false;

	constructor(dictionaryName: string, resultsCount: number, entries: string[], pageSize: number, pageIndex: number, pageResultsCount: number) {
		this.dictionaryName = dictionaryName;
		this.resultsCount = resultsCount;
		this.entries = entries;
		this.pageSize = pageSize;
		this.pageIndex = pageIndex;
		this.pageResultsCount = pageResultsCount;
	}
}

export class QueryResults {
	queryString: string;
	result: QueryResultData;

	constructor(queryString: string, queryResults: QueryResultData) {
		this.queryString = queryString;
		this.result = queryResults;
	}
}
