import React from "react"

import searchBtn from "../res/search_btn.png"
import searchBtnBusy from "../res/search_btn_busy.gif"

export default function Search(params: any) {

	return <div className="flex flex-row items-center">
		<input type="text" className="outline-0 border-2 border-red-700 py-0 px-4 rounded-full h-[41px] w-2/3"
		       placeholder={`Minimalna długość frazy: ${params.minQueryLength}`}
		       value={params.queryString}
		       onChange={(e) => params.onQueryStringChange(e.target.value)}
		       onKeyDown={(e) => {
			       if (e.key === 'Enter') {
				       params.onSearch();
			       }
		       }}
		/>
		<input type="image" src={params.progressVisible ? searchBtnBusy : searchBtn}
			   onClick={params.onSearch} alt={"Szukaj"}
		       className="h-[60px] rounded-full relative left-[-30px]"/>
	</div>
}