import React, {useEffect, useState} from 'react'

import index from "./api/api"

import {QueryResultData, QueryResults} from "./model/QueryResults"
import {DictionaryDescriptor} from "./model/DictionaryDescriptor"
import {QueryFilters, QueryParams} from "./model/QueryParams"

import PageHeader from "./components/PageHeader"
import Search from "./components/Search"
import ShowResults from "./components/ShowResults"
import Paging from "./components/Paging"
import Filters from "./components/Filters"

import banner from "./res/banner.png"

function App() {

    const MIN_QUERY_LENGTH: number = 2;
    const DEFAULT_DICTIONARY: string = "";
    const DEFAULT_PAGE_SIZE: number = 999;
    const DEFAULT_PAGE_INDEX: number = 0;
    const EMPTY_FILTERS: QueryFilters = new QueryFilters();

    const [qStr, setQStr] = useState("");
    const [filtered, setFiltered] = useState(false)
    const [filters, setFilters] = useState(EMPTY_FILTERS);
    const [dictName, setDictName] = useState(DEFAULT_DICTIONARY);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
    const [selectedPage, setSelectedPage] = useState(DEFAULT_PAGE_INDEX + 1);

    const [data, setData]
        = useState(() => new QueryResults("",
        new QueryResultData(dictName, 0, [], 0, 0, 0)
    ));
    const [dictionaries, setDictionaries] = useState(() => []);

    const [progressVisible, setProgressVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchDictionaries = async () => {
        try {
            const res = await index.get(`/dictionaries`);
            setDictionaries(res.data);
            setDictName(res.data[0].name);
            setSelectedPage(DEFAULT_PAGE_INDEX + 1);
        } catch (err) {
            console.error(err);
        }
    };

    const shouldHandleResults = () => {
        return qStr && qStr.length >= MIN_QUERY_LENGTH;
    }

    const resultsAvailable = () => {
        return data.result.resultsCount > 0;
    }

    function setupParams() {
        let params = new QueryParams(qStr, dictName, pageSize, pageIndex, data.result.skippedPages, filters);
        params.filtered = filtered;
        return params;
    }

    const query = async () => {
        if (shouldHandleResults()) {
            try {
                let params = setupParams();
                console.debug("params: ", params);
                setProgressVisible(true);
                index.post(`/query`, params)
                    .then(res => {
                        console.debug("response; ", res.data);
                        setData({...data, result: res.data, queryString: qStr});
                        setProgressVisible(false);
                    })
                    .catch(reason => {
                        console.log(reason);
                    })
            } catch (err) {
                console.error(err);
            }
        }
    };

    function search() {
        setPageIndex(0);
        query();
    }

    function onSelectedPageChange(value: string) {
        let page = Number.parseInt(value);
        if (!isNaN(page)) {
            setSelectedPage(page);
        }
    }

    function setDisplayedPage(index: number) {
        setPageIndex(index);
        setSelectedPage(index + 1);
    }

    useEffect(() => {
        if (dictionaries.length === 0) {
            fetchDictionaries();
        }
    }, [dictionaries]);

    useEffect(() => {
        query();
    }, [pageIndex]);


    function onPrevPage() {
        return () => setDisplayedPage(pageIndex - 1);
    }

    function onNextPage() {
        return () => setDisplayedPage(pageIndex + 1);
    }

    function onSelectPage() {
        return (page: number) => setDisplayedPage(page);
    }

    function descriptorToOption() {
        return (dd: DictionaryDescriptor) => <option key={dd.name} value={dd.name}>{dd.displayedName}</option>;
    }

    function onFiltersClose() {
        console.debug('filters close')
        setFiltered(false);
    }

    function onFiltersOpen() {
        console.debug('filters open')
        setFiltered(true);
    }

    function onFiltersClear() {
        console.debug('filters clear')
        setFilters(EMPTY_FILTERS);
    }

    let resultsCountTitle = `Są to wyniki otrzymywane z pominięciem fitrów, a więc wszystkie słowa bezpośrednio pasujące do wyszukiwanej frazy "${qStr}"`;

    return (
        <div>
            <PageHeader/>

            <div className="flex flex-col justify-center space-x-3 space-y-2 w-[1020px] mx-auto m-10 text-red-700">
                
				<span className="flex flex-row justify-center text-red-700 font-bold text-[32px]">
					<img src={banner}/>
				</span>

                <Search queryString={qStr} minQueryLength={MIN_QUERY_LENGTH} progressVisible={progressVisible}
                        onQueryStringChange={setQStr} onSearch={search}/>

                <div className={'flex flex-row '}>

                    <div className={'flex flex-col w-1/2 space-y-2'}>

                        <div className="flex flex-row items-center">
                            <div className="w-1/3">wybierz słownik:</div>
                            <select value={dictName} className="px-2 font-bold bg-inherit"
                                    onChange={(e) => setDictName(e.target.value)}>
                                {dictionaries.map(descriptorToOption())}
                            </select>
                        </div>

                        {resultsAvailable() ?
                            <>
                                <div className="flex flex-row space-x-3">
                                    <span className="w-1/3">znalezione wyniki:</span>
                                    <span className="font-bold">{data.result.resultsCount}</span>
                                    {data.result.filtered ?
                                        <span title={resultsCountTitle}> (bez filtrów) <i
                                            className="icon-question-sign"></i></span>
                                        : null}
                                </div>

                                <div className="flex flex-row space-x-3">
                                    <span className="w-1/3">wyników na stronie:</span>
                                    <input type={"radio"} name="pageSize"
                                           defaultChecked={pageSize === DEFAULT_PAGE_SIZE}
                                           onClick={() => setPageSize(999)}/>&nbsp;999&nbsp;
                                    <input type={"radio"} name="pageSize"
                                           defaultChecked={pageSize !== DEFAULT_PAGE_SIZE}
                                           onClick={() => setPageSize(99)}/>&nbsp;99&nbsp;
                                </div>

                                <div className="flex flex-row space-x-3 items-center">
                                    <span className="w-1/3">wyświetl stronę:</span>
                                    <input type="text"
                                           className="outline-0 border-2 border-red-700 px-4 rounded-full w-[100px]"
                                           onChange={(e) => onSelectedPageChange(e.target.value)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   setPageIndex(selectedPage - 1);
                                               }
                                           }}
                                           value={selectedPage}
                                    />
                                    {data.result.filtered && data.result.lastPage ?
                                        <div className="flex flex-row space-x-3">
                                            <span> (ostatnia strona filtrowanych wyników)</span>
                                        </div> : null
                                    }
                                </div>
                            </> : null
                        }
                    </div>

                    <div className={'flex flex-col w-1/2 place-content-start'}>
                        <Filters filters={filters} onOpen={onFiltersOpen} onClose={onFiltersClose}
                                 onClear={onFiltersClear}/>
                    </div>
                </div>

                {resultsAvailable() ?
                    <>
                        <Paging data={data.result} onSelectPage={onSelectPage()}
                                onPrevPage={onPrevPage()} onNextPage={onNextPage()}/>

                        {qStr ? <ShowResults data={data}/> : null}

                        <Paging data={data.result} onSelectPage={onSelectPage()}
                                onPrevPage={onPrevPage()} onNextPage={onNextPage()}/>
                    </> : null
                }
            </div>

        </div>
    );
}

export default App;
