export class QueryFilters {

    static AND_OPERATOR: string = '&';
    static OR_OPERATOR: string = '|';

    static EQ_OPERATTOR: string = '=';
    static LT_OPERATTOR: string = '<';
    static GT_OPERATTOR: string = '>';

    static INVALID_LENGTH: string = '';

    including: string[] = [];
    includingOperator: string = QueryFilters.AND_OPERATOR;
    excluding: string[] = [];
    startingWith: string = '';
    notStartingWith: string = '';
    endingWith: string = '';
    notEndingWith: string = '';
    length: string = QueryFilters.INVALID_LENGTH;
    lengthOperator: string = QueryFilters.EQ_OPERATTOR;

    empty(): boolean {
        return this.including.length == 0
            && this.excluding.length == 0
            && this.startingWith.length == 0
            && this.notStartingWith.length == 0
            && this.endingWith.length == 0
            && this.notEndingWith.length == 0
            && this.length == QueryFilters.INVALID_LENGTH;
    }
}

export class QueryParams {

    queryString: string;
    dictionaryName: string;
    pageSize: number;
    pageIndex: number;

    filtered: boolean = false;
    skippedPages: number[];
    filters: QueryFilters;


    constructor(queryString: string, dictionaryName: string, pageSize: number, pageIndex: number, skippedPages: number[], filters: QueryFilters) {
        this.queryString = queryString;
        this.dictionaryName = dictionaryName;
        this.pageSize = pageSize;
        this.pageIndex = pageIndex;
        this.skippedPages = skippedPages;
        this.filters = filters;
    }
}