import React, {useState} from "react"
import {QueryFilters} from "../model/QueryParams"

export default function Filters(params: any) {

    const separator = ','

    const [open, setOpen] = useState(false)

    const [including, setIncluding] = useState(joinParams(params.filters.including))
    const [includingOperator, setIncludingOperator] = useState(params.filters.includingOperator)
    const [excluding, setExcluding] = useState(joinParams(params.filters.excluding))
    const [startingWith, setStartingWith] = useState(params.filters.startingWith)
    const [notStartingWith, setNotStartingWith] = useState(params.filters.notStartingWith)
    const [endingWith, setEndingWith] = useState(params.filters.endingWith)
    const [notEndingWith, setNotEndingWith] = useState(params.filters.notEndingWith)
    const [length, setLength] = useState(params.filters.length)
    const [lengthOperator, setLengthOperator] = useState(params.filters.lengthOperator)

    let chevronStyle = open ? '' : ' icon-rotate-270 '
    let mainTooltip = open
        ? 'Surowe wyniki ze słownika są filtrowane przez zestaw poniższych parametrów'
        : 'Rozwiń, by aktywować filtry dla zapytania'
    let filterOperatorTooltip = 'Wybierz operator filtra'

    let color = 'red-700'
    let leftColWidth = 'w-2/3'
    let rightColWidth = 'w-1/3'

    function clearFilters() {
        setIncluding('')
        setIncludingOperator(QueryFilters.AND_OPERATOR)
        setExcluding('')
        setStartingWith('')
        setNotStartingWith('')
        setEndingWith('')
        setNotEndingWith('')
        setLength(QueryFilters.INVALID_LENGTH)
        setLengthOperator(QueryFilters.EQ_OPERATTOR)

        params.onClear()
    }

    function toggleOpen() {
        return () => {
            if (open) {
                params.onClose()
            } else {
                params.onOpen()
            }
            setOpen(!open)
        }
    }

    function splitParams(s: string) {
        let res: string[] = []
        s.split(separator)
            .map(s => {
                let trimmed = s.trim()
                if (trimmed.length > 0) {
                    res.push(trimmed)
                }
            })
        return res
    }

    function joinParams(s: string[]): string {
        return s.join(separator)
    }

    function onIncludingChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setIncluding(e.target.value)
            params.filters.including = splitParams(e.target.value)
        }
    }

    function onIncludingOperatorChange() {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            setIncludingOperator(e.target.value)
            params.filters.includingOperator = e.target.value
        }
    }

    function onExcludingChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setExcluding(e.target.value)
            params.filters.excluding = splitParams(e.target.value)
        }
    }

    function onStartingWithChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setStartingWith(e.target.value)
            params.filters.startingWith = e.target.value
        }
    }

    function onNotStartingWithChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setNotStartingWith(e.target.value)
            params.filters.notStartingWith = e.target.value
        }
    }

    function onEndingWithChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setEndingWith(e.target.value)
            params.filters.endingWith = e.target.value
        }
    }

    function onNotEndingWithChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setNotEndingWith(e.target.value)
            params.filters.notEndingWith = e.target.value
        }
    }

    function onLengthChange() {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setLength(e.target.value)
            params.filters.length = e.target.value
        }
    }

    function onLengthOperatorChange() {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            setLengthOperator(e.target.value)
            params.filters.lengthOperator = e.target.value
        }
    }

    return <div className={`flex flex-col `} title={mainTooltip}>

        <div className={`flex flex-row space-x-2`}>
            <div className={`items-center justify-center py-1 font-bold ${leftColWidth} cursor-pointer`}
                 onClick={toggleOpen()}>
                <i className={`icon-chevron-down ${chevronStyle} text-xs`}>&nbsp;&nbsp;</i>
                <span>Dodatkowe filtry {open ? '(aktywne)' : ''}</span>
            </div>
            <div className={`font-bold ${rightColWidth} text-center`}>
                {open ? <i className={`icon-eraser cursor-pointer`} title={'Wyczyść filtry'}
                           onClick={clearFilters}></i> : null}
            </div>
        </div>

        {open ? <>

            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-t-2 border-b border-${color}`}>
                    Zawierające dodatkowo
                    <select id="filter-including-operator" className={`outline-0 bg-inherit font-bold`}
                            title={filterOperatorTooltip}
                            value={includingOperator} onChange={onIncludingOperatorChange()}>
                        <option key={0} value={QueryFilters.AND_OPERATOR}>wszystkie z</option>
                        <option key={1} value={QueryFilters.OR_OPERATOR}>dowolne z</option>
                    </select>
                    :
                </div>
                <div className={`${rightColWidth} border-t-2 border-b border-${color}`}>
                    <input id="filter-including" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                           value={including} onChange={onIncludingChange()}/>
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Ńe zawierające:
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-excluding" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                           value={excluding} onChange={onExcludingChange()}/>
                </div>
            </div>

            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Zaczynające śę od:
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-starting-with" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                           value={startingWith} onChange={onStartingWithChange()}/>
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Ńe zaczynające śę od:
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-not-starting-with" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                          value={notStartingWith}  onChange={onNotStartingWithChange()}/>
                </div>
            </div>

            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Kończące śę na:
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-ending-with" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                           value={endingWith} onChange={onEndingWithChange()}/>
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Ńe kończące śę na:
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-not-ending-with" type="text" className={`mt-1 w-full outline-0 bg-inherit`}
                           value={notEndingWith} onChange={onNotEndingWithChange()}/>
                </div>
            </div>

            <div className="flex flex-row space-x-2">
                <div className={`text-right py-1 ${leftColWidth} border-b border-${color}`}>
                    Ilość liter
                    <select id="filter-length-operator" className={`outline-0 bg-inherit font-bold`}
                            title={filterOperatorTooltip}
                            value={lengthOperator} onChange={onLengthOperatorChange()}>
                        <option key={0} value={QueryFilters.EQ_OPERATTOR}>równa</option>
                        <option key={1} value={QueryFilters.LT_OPERATTOR}>mńejsza od</option>
                        <option key={2} value={QueryFilters.GT_OPERATTOR}>większa od</option>
                    </select>
                    :
                </div>
                <div className={`${rightColWidth} border-b border-${color}`}>
                    <input id="filter-length" type="number"
                           className={`mt-1 w-full outline-0 bg-inherit`}
                           value={length} onChange={onLengthChange()}/>
                </div>
            </div>

        </> : null}
    </div>
}