export default function ShowResults(params: any) {

	function formatEntry(entry: string) {
		let [phrase, pos] = entry.split(":");
		let position: number = Number(pos);
		let prefix = phrase.slice(0, position);
		let morfemEnd: number = position + params.data.queryString.length;
		let morfem = phrase.slice(position, morfemEnd);
		let suffix = phrase.slice(morfemEnd);

		return <li key={entry}>
			<span className="text-black">{prefix}</span>
			<span className="text-red-600">{morfem}</span>
			<span className="text-black">{suffix}</span>
		</li>;
	}

	let result = params.data.result;
	if (result.entries.length > 0) {
		return <div>
			<ul className="mx-4 list-disc">
				{result.entries.map((entry: string) => formatEntry(entry))}
			</ul>
		</div>
	}
	return <div className={"elementor-container elementor-column-gap-default"}>Brak wyników</div>
}
