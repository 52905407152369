import React from "react";
import {QueryResultData} from "../model/QueryResults";

const EDGE = 3;
const SURROUNDING = 2;
const SINGLE_ELLIPSIS_TRIGGER = 2 * EDGE;

const ELLIPSIS_MARK = -1;
const BEGINNING_PAGE_INDICES = range(0, EDGE);

/**
 * Returns range counting given elements, starting from given index.
 *
 * @param start
 * @param count
 */
function range(start: number, count: number): number[] {
    return Array.from(Array(count).keys()).map(v => start + v)
}

export function calculateRanges(pageIndex: number, pagesCount: number) {
    if (pagesCount <= SINGLE_ELLIPSIS_TRIGGER) {
        return range(0, pagesCount);
    }

    let maxPageIndex = pagesCount - 1;
    let surr = range(pageIndex - SURROUNDING, 2 * SURROUNDING + 1);
    let ending = range(pagesCount - EDGE, EDGE);
    let c = BEGINNING_PAGE_INDICES.concat(surr).concat(ending)
        .sort((a, b) => a - b);
    let r = [];

    for (let i = 0; i < c.length - 1; i++) {
        let curr = c[i];
        let next = c[i + 1];
        if (curr < 0 || curr > maxPageIndex || curr == next) {
            continue;
        }
        r.push(curr);
        if (next - curr != 1) {
            r.push(ELLIPSIS_MARK);
        }
    }
    let last = c[c.length - 1];
    if (last <= maxPageIndex) {
        r.push(last);
    }
    return r;
}

export default function Paging(params: any) {

    function prevPage() {
        if (params.data.pageIndex > 0) {
            params.onPrevPage();
        }
    }

    function nextPage(pagesCount: number) {
        let lastPageIndex = pagesCount - 1;
        if (params.data.pageIndex < lastPageIndex) {
            params.onNextPage();
        }
    }

    function renderPageLink(pageIndex: number) {
        if (pageIndex == ELLIPSIS_MARK) {
            return <span key={pageIndex} className="inline">... ,</span>;
        }
        return <div key={pageIndex}>
            <a href="#"
               className={pageIndex == params.data.pageIndex ? "inline text-2xl font-bold" : ""}
               onClick={() => params.onSelectPage(pageIndex)}>&nbsp;{pageIndex + 1}</a>
            <span>,&nbsp;</span>
        </div>;
    }

    function renderPageNumbers(pagesCount: number) {
        return <>
            {calculateRanges(params.data.pageIndex, pagesCount).map(renderPageLink)}
        </>;
    }

    function renderPaging(pagesCount: number) {
        return <span className="flex flex-row justify-center items-end align-baseline">
			<a href="#" onClick={prevPage}>&lt;&lt; Poprzednia, </a>
            {renderPageNumbers(pagesCount)}
            <a href="#" onClick={() => nextPage(pagesCount)}> Następna &gt;&gt;</a>
		</span>
    }

    function nextPageFiltered(pagesCount: number) {
        if (!params.data.lastPage) {
            params.onNextPage();
        }
    }

    function renderFilteredPaging(data: QueryResultData) {
        let pagesCount: number = data.skippedPages.length;
        return <span className="flex flex-row justify-center items-end align-baseline">
			<a href="#" onClick={prevPage}>&lt;&lt; Poprzednia, </a>
            {renderPageNumbers(pagesCount)}
            <a href="#" onClick={() => nextPageFiltered(pagesCount)}> Następna &gt;&gt;</a>
		</span>
    }

    let data: QueryResultData = params.data;

    if (data.filtered) {
        return renderFilteredPaging(data)
    }

    let pagesCount = Math.ceil(data.resultsCount / data.pageSize);
    if (pagesCount > 1) {
        return renderPaging(pagesCount);
    }

    return null;
}